<template>
  <div>
    <Home></Home>
  </div>
</template>

<script>
import Home from "@/components/Home";
export default {
  name: "HomeView",
  components: {Home}
}
</script>

<style scoped>

</style>