<template>
  <div>
    <div>
      <v-carousel
          cycle
          interval="2500"
          height="400"
          hide-delimiter-background
          show-arrows-on-hover
      >
        <v-carousel-item v-for="(item, index) in items.filter(x=>x.lang===lang)" :key="index">
          <v-responsive :aspect-ratio="4/3" contain>
            <v-img :src="item.src" />
          </v-responsive>
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-row class="ma-2 align-center text-md-center">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" md="4" >
        <Media
            :kind="'video'"
            :isMuted="(false)"
            src="https://point.jatimprov.go.id:8686/api/pirjatim/storage/stream/mp4/PointJatim_720.mp4"
            :controls="true"
            :autoplay="false"
            :ref="'fish'"
            :style="{width: '450px'}"
            class="rounded-lg elevation-10"
        >
        </Media>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="7" md="7" class="ml-4">
        <div class="ml-4">
          <v-img
              src="../assets/images/background_1.jpg"
              style="background-size: inherit "
              height="220px"
              width="100%"
              gradient="to top right, green 20%, transparent 95%"
              class="rounded-lg elevation-10"
          >
            <div class="pa-6 white--text text-md-center glow">
              <span class="font-weight-black text-h4">POINT JATIM</span>
              <div class="font-italic font-weight-bold subtitle-1">(Potential Opportunity and Investment Jawa Timur)</div>

              <div class="mt-2 font-weight-bold">
                {{$t('adalah')}} <span class="font-italic">Mall </span> {{$t('homeText')}}
              </div>
            </div>
          </v-img>
        </div>

      </v-col>
    </v-row>

    <PotensiPeluangView class="mt-4"></PotensiPeluangView>

  </div>
</template>

<script>
import Media from '@dongido/vue-viaudio'
import PotensiPeluangView from "@/views/public/PotensiPeluangView";

export default {
  name: "Home",
  components: {PotensiPeluangView, Media},
  data () {
    return {
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],

      itemsEnxx: [
        { src: "kek_en.png" },
        { src: "menteri_en.png" },
        { src: "jokowi_en.png" },
        { src: "peluang_investasi_.png" },
      ],
      itemsId: [
        { src: "kek_id.png" },
        { src: "menteri_id.png" },
        { src: "jokowi_id.png" },
        { src: "peluang_investasi_id.png" },
      ],

      items: [
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/Jokowi_INDO.png',
          title: '',
          description: '',
          lang: 'id'
        },
        {
          src: `../assets/images/bu_khofifah.png`,
          title: '',
          description: '',
          lang: 'id'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/1643335980_IDN_Menteri_INDO.png',
          title: '',
          description: '',
          lang: 'id'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/1643354683_IDN_KEK_INDO.png',
          title: '',
          description: '',
          lang: 'id'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/Insentif_INDO%20400.png',
          title: '',
          description: '',
          lang: 'id'
        },

        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/Jokowi_ENG.png',
          title: '',
          description: '',
          lang: 'en'
        },
        {
          src: `../assets/images/bu_khofifah.png`,
          title: '',
          description: '',
          lang: 'en'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/1643335980_EN_Menteri_ENG.png',
          title: '',
          description: '',
          lang: 'en'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/1643354683_EN_KEK_ENG.png',
          title: '',
          description: '',
          lang: 'en'
        },
        {
          src: 'https://regionalinvestment.bkpm.go.id/admin//upload/slider/Insentif_ENG%20400.png',
          title: '',
          description: '',
          lang: 'en'
        },
      ],



    }
  },
  computed: {
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
  },
}
</script>

<style scoped>

.bg-gradient {
  background: linear-gradient( to top right, rgba(245, 172, 39, 0.1), rgba(252, 250, 247, 0.5)),
  url('../assets/images/background_1.jpg') center center / cover no-repeat;
  opacity: 0.5;
}

.glow {
  font-size: 20px;
  color: #fff;
  text-align: center;
  animation: glow 2s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 3px #e4f6ee, 0 0 3px #4777de, 0 0 3px #e9f8f3, 0 0 3px #142a86, 0 0 3px #e60073, 0 0 3px #e60073, 0 0 10px #e60073;
  }

  to {
    text-shadow: 0 0 5px #eaf8f3, 0 0 5px #4777de, 0 0 5px #eaf5f1, 0 0 5px #233ca9, 0 0 5px #ff4da6, 0 0 5px #ff4da6, 0 0 20px #ff4da6;
  }
}

</style>